import React from 'react';
import { NavLink } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import styles from './HorizontalMenu.scss';

const HorizontalMenu = () => {
  const menuItems = [
    {
      title: <Trans>About us</Trans>,
      url: '/about-us',
    },
    {
      title: <Trans>Join us</Trans>,
      url: '/join-us',
    },
    {
      title: <Trans>Press</Trans>,
      url: '/press',
    },
    {
      title: <Trans>Contact us</Trans>,
      url: '/contact-us',
    },
    {
      title: <Trans>Blog</Trans>,
      url: '/blog',
    },
  ];
  return (
    <nav className={`navbar is-light ${styles.navbar}`}>
      <div className={`navbar-menu ${styles.navbarMenu}`}>
        <div className="navbar-start">
          {menuItems.map(item => (
            <NavLink
              key={item.url}
              className={`navbar-item ${styles.navbarLink}`}
              activeClassName={styles.active}
              to={item.url}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default HorizontalMenu;
