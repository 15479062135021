// @flow

import React, { Component } from 'react';
import type { ContextRouter } from 'react-router';
import type { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Trans } from '@lingui/macro';

import type { CommonActionsFlowType } from 'modules/common/actions';
import { actions as commonActions } from 'modules/common/actions';
import HorizontalMenu from 'modules/visitor/horizontalMenu';

type Props = { dispatch: Dispatch<CommonActionsFlowType> } & ContextRouter;

class NotFoundView extends Component<Props> {
  componentWillMount() {
    this.props.dispatch(commonActions.onPageSetStatus(404));
  }

  render() {
    const search = queryString.parse(this.props.location.search);
    const { statusCode, errorTitle, errorMessage } = search;
    return (
      <div>
        <HorizontalMenu />
        <div className="container mt-50 mb-50 has-text-centered pl-30 pr-30">
          <h1 className="is-size-2 title">{statusCode || 404}</h1>
          <h2 className="is-size-4 title">{errorTitle || <Trans>That’s an error!</Trans>}</h2>
          <p>{errorMessage || <Trans>The requested URL was not found on this server. That’s all we know.</Trans>}</p>
          <div className="pt-40">
            <Link to="/" className="button is-primary">
              <Trans>Back Home</Trans>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundView;
